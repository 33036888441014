<template>
  <main>
    <keep-alive v-if="$route.meta.keepAlive">
      <router-view></router-view>
    </keep-alive>
    <router-view v-else></router-view>
  </main>
</template>

<script>
export default {
  data: () => ({
    
  }),
  methods: {
    
  }
}
</script>

<style lang="scss" scoped>

</style>